import React from "react";
import { InfoListWrapper, ListItem, ListWrapper } from "./styled";
import {
	CustomButton,
	CustomButtonWrapper,
	CustomLink,
} from "../../../assets/styles/globalCommon";
import { ReactSVG } from "react-svg";

const InfoList = (props) => {
	const { title, columns, items } = props;
	return (
		<InfoListWrapper>
			<h5>{title}</h5>

			<ListWrapper>
				<ListItem className="columns">
					{columns?.map((col, index) => (
						<p key={index}>{col}</p>
					))}
					<p />
				</ListItem>

				<div className="list">
					{items?.map((item, index) => {
						const {
							firstCol,
							secondCol,
							thirdCol,
							buttonUrl,
							buttonText,
						} = item;
						return (
							<ListItem key={index}>
								<p>{firstCol}</p>
								<p>{secondCol}</p>
								<p>{thirdCol}</p>

								{buttonUrl && buttonText && (
									<CustomLink
										to={buttonUrl}
										className="ghost onlyIcon"
									>
										<ReactSVG
											src="/img/icons/icon-arrow-down.svg"
											wrapper="span"
											className="icon"
										/>
										{/*<p>{buttonText}</p>*/}
									</CustomLink>
								)}
							</ListItem>
						);
					})}
				</div>

				<CustomButtonWrapper>
					<CustomButton onClick={() => alert("ver mas")}>
						<p>Ver más</p>
					</CustomButton>
				</CustomButtonWrapper>
			</ListWrapper>
		</InfoListWrapper>
	);
};

export default InfoList;
