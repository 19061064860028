import React, { useEffect } from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { useIntl } from "gatsby-plugin-intl";
import { connect } from "react-redux";
import { appRedirect } from "../redux/app/actions";
import {
	PageContentWrapper,
	PageContent,
	PageTwoColWrapper,
	PageMessage,
} from "../assets/styles/pageCommon";
import { colors } from "../assets/styles/vars";
import PageHeader from "../components/Common/PageHeader";
import {
	Breadcrumb,
	CustomButton,
	CustomLink,
} from "../assets/styles/globalCommon";
import { Link } from "gatsby";
import ModulesList from "../components/Common/ModulesList";
import MessageBox from "../components/Common/MessageBox";
import InfoList from "../components/Common/InfoList";
import { protegemosDatosModule, recibosData } from "../data/global-data";
import { SliderButton } from "@typeform/embed-react";

const SuscripcionPage = ({ authResolved, loggedIn, usuario, appRedirect }) => {
	const intl = useIntl();

	useEffect(() => {
		if (authResolved && !loggedIn) appRedirect({ to: "/" });
	}, [authResolved, loggedIn]);

	return (
		<Layout>
			<Seo title="Mi Suscripción" />
			<section>
				<PageHeader title="Pagos" />

				<PageContentWrapper bgColor={colors.grayF2}>
					<PageContent width="1050px">
						<PageTwoColWrapper>
							<div className="pageCol leftCol">
								<Breadcrumb>
									<Link to="/account">Mi cuenta</Link>
									<a>&gt;</a>
									<a>Mi Suscripción</a>
								</Breadcrumb>
								<p>
									Realiza un seguimiento y administración de tu
									suscripción, pagos y facturas.
								</p>
								<br />

								{/* <MessageBox
									icon="/img/icons/icon-info.svg"
									type="error"
									text="Problemas con tu pago Problemas con tu pago Problemas con tu pago"
									buttonText="Actualizar el pago"
									buttonUrl="/pagos"
								/>

								<MessageBox
									icon="/img/icons/icon-bell.svg"
									type="warning"
									text="Se esta por vencer tu suscripción"
									buttonText="Actualizar el pago"
									action={() => alert("WARNING")}
								/>

								<MessageBox
									icon="/img/icons/icon-check.svg"
									type="success"
									text="Pago realizado con exito"
								/> */}

								{authResolved && loggedIn && (
									<>
										{usuario.premium && (
											<PageMessage>
												<div className="PageMessageText">
													<p>
														Estás suscripto al plan{" "}
														<strong>Premium/Smart</strong>
													</p>
													{/* <p>
															Fecha de renovación automática:
															10/10/2023
														</p> */}
												</div>
												<div className="PageMessageAction">
													<SliderButton id="kr8xczVw">
														<a className="ghost withUnderline">
															<p>Cancelar</p>
														</a>
													</SliderButton>
												</div>
											</PageMessage>
										)}

										{!usuario.premium && (
											<PageMessage>
												<div className="PageMessageText">
													<p>
														Estás suscripto al plan{" "}
														<strong>Starter</strong>
													</p>
													{/* <p>
															Fecha de ronavación automática:
															10/10/2023
														</p> */}
												</div>
												<div className="PageMessageAction">
													<CustomLink
														to="/subscriptions"
														className="ghost withUnderline"
													>
														<a className="ghost withUnderline">
															<p>Convertirme en premium</p>
														</a>
													</CustomLink>
												</div>
											</PageMessage>
										)}

										<InfoList
											title="Recibos"
											columns={["Fecha", "Referencia", "Importe"]}
											items={recibosData}
										/>
									</>
								)}
							</div>
							<div className="pageCol rightCol">
								<ModulesList items={protegemosDatosModule} />
							</div>
						</PageTwoColWrapper>
					</PageContent>
				</PageContentWrapper>
			</section>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		authResolved: state.auth.resolved,
		loggedIn: state.auth.loggedIn,
		usuario: state.auth.usuario,
	};
};

const mapDispatchToProps = {
	appRedirect,
};

export default connect(mapStateToProps, mapDispatchToProps)(SuscripcionPage);
