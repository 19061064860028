import styled from "styled-components";
import { colors, mediaQueryMax } from "../../../assets/styles/vars";
import { CustomButtonWrapper } from "../../../assets/styles/globalCommon";

export const InfoListWrapper = styled.section`
	padding-top: 50px;
	width: 100%;
	overflow: hidden;
	h5 {
		margin-bottom: 24px;
	}
	${CustomButtonWrapper} {
		@media ${mediaQueryMax.sm} {
			button {
				width: 100%;
				box-sizing: border-box;
			}
		}
	}
`;

export const ListWrapper = styled.section`
	background-color: ${colors.white};
	border-radius: 5px;
	padding: 24px;
	.list {
		border-bottom: 1px solid ${colors.gray40};
		margin-bottom: 24px;
	}
	@media ${mediaQueryMax.md} {
		padding: 24px 10px;
	}
`;

export const ListItem = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 10px;
	border-radius: 5px;
	overflow: hidden;
	padding: 20px 0 20px 24px;
	background-color: ${colors.light20};
	margin-bottom: 24px;
	@media ${mediaQueryMax.md} {
		padding: 20px 0 20px 10px;
		margin-bottom: 15px;
	}
	p {
		line-height: 1.7;
		color: ${colors.black00} !important;
		flex: 1;
	}
	a,
	button {
		margin-left: 20px;
	}
	@media ${mediaQueryMax.sm} {
		p {
			font-size: 15px;
		}
		a,
		button {
			margin: 0;
		}
	}
	@media ${mediaQueryMax.xs2} {
		p {
			font-size: 12px;
		}
		a,
		button {
			span.icon {
				width: 30px;
			}
		}
	}
	&.columns {
		background-color: transparent;
		padding: 0 0 0 24px;
		p {
			color: ${colors.gray} !important;
			&:last-of-type {
				flex: 0 0 70px;
				max-width: 70px;
				@media ${mediaQueryMax.sm} {
					flex: 0 0 50px;
					max-width: 50px;
				}
			}
		}
		@media ${mediaQueryMax.md} {
			padding: 0 0 0 10px;
		}
	}
`;
